// Override default variables before the import
// Import Bootstrap and its default variables
$body-bg: #FEF9F6;

@import "variables";
@import '~bootstrap/scss/bootstrap.scss';
@import "base/fonts";
@import 'react-loading-skeleton/dist/skeleton.css';

:root {
    --bs-primary-rgb: 254, 249, 253;
    --bs-secondary-rgb: 213, 197, 173;
    --bs-tertiary-rgb: 183, 173, 161;
}


html {
    font-size: 10px;
}

