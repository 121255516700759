/* abril-fatface-regular - latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    src: local('Cabin Regular'), local('Cabin-Regular'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/Cabin-Regular.woff2') format('woff2'),
        /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/Cabin-Regular.woff') format('woff');
}

$font-family-base: 'Cabin';
$font-family-sans-serif: 'Cabin';