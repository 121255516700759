@import "swiper/css";
@import "swiper/css/free-mode";

.swiper-slide {
    width: 150px;
    height: 150px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    transition: all 0.2s ease-in-out;

    figure {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    &:hover {
        //transition-property: all;
        transform: scale(1.2);
        //margin-top: -0.2em;
        z-index: 9999;
    }
}

.instagramlist {
    //list-style: none;
    //overflow-x: hidden;
    white-space: nowrap;

    &>li {
        display: inline-block;
        margin: 1rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            transition-property: all;
            transform: scale(1.1);
        }
    }

    figcaption {
        display: none;
    }
}