header {
    position: sticky;
    background-color: azure;
    border-bottom: 0.05rem solid;

    .navbar-header {
        position: sticky;
        //img {
        //    max-height: 180px;
        //}
    }

    .navbar {
        position: sticky;
    }
}
