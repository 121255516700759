.main-menu {
    .react-loading-skeleton {
        margin-left: 1rem;
        --base-color: rgba(var(--bs-secondary-rgb));
        --highlight-color: rgba(var(--bs-primary-rgb));
        --animation-duration: 2s;
    }


    .navbar-nav {
        //--bs-nav-link-color: var($body-color);
        //--bs-nav-link-hover-color: var(--bs-navbar-hover-color);
        //--bs-nav-link-disabled-color:
        .nav-item {
            --bs-navbar-nav-link-padding-x: 1.0rem;
            font-weight: 600;
            font-size: 1.6rem;

            border-bottom: 0.1rem solid transparent;

            &:hover, &.active {
                border-bottom: 0.1rem solid var(--bs-navbar-hover-color);
            }


            //.divider {
            //    width: 4rem;
            //    display: inline-block;
            //}
        }
        .nav-divider {
            width: 2rem;
        }
    }


    //a {
    //    color: red;
    //}
}