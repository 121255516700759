@import "/src/style/variables";

footer {
    //position: absolute;
    bottom: 0;
    //width: 100%;
    height: auto;
    //line-height: 60px;
    background-color: #f5f5f5;

    .copyright {
        border-top: 0.1rem $border-color;
        padding-top: 0.1rem;
        font-size: 1.0rem;
    }
}