
// General colors palette
$colors: (
        "transparent": transparent,
        "black": #000,
        "white": #fff,
        "white-smoke": #f2f2f2,
        "gainsboro": #e0e0e0,
        "silver": #bdbdbd,
        "gray": #F0EFEC,
        "text-grey": "#556677",
        "suva-gray": #8e8e8e,
        "matterhorn": #4f4f4f,
        "burnt-sienna": #eb5757,
        "buccaneer": #755,
        "forest-green": #308c14,
        "puerto-rico": #4dba87,
        "mine-shaft": #333,
        "brown": #504538,
        "lighter-brown": #745E43,
        "orange-brown": #BF512C,
        "light-brown": #F1EEEC,
);

// Theme generic colors
// Key: name of color
// Value: color value or map with default, hover, focus color values
$colors-theme: (
        "primary": (
                "default": map-get($colors, "brown"),
                "hover": darken(map-get($colors, "brown"), 10%)
        ),
        "secondary": (
                "default": map-get($colors, "orange-brown"),
                "hover": darken(map-get($colors, "brown"), 10%),
        ),
        "accent": (
                "default": map-get($colors, "lighter-brown"),
                "hover": map-get($colors, "mine-shaft"),
        ),
        "tertiary": map-get($colors, "silver"),
        "success": map-get($colors, "puerto-rico"),
        "warning": map-get($colors, "buccaneer"),
        "error": map-get($colors, "burnt-sienna")
);

$colors-button: (
        "default": map-get($colors, "orange-brown"),
        "light": map-get($colors, "white"),
        "dark": map-get($colors, "orange-brown")
);

//$colors-border: (
//        "default": #FEF9F6,
//        "secundair": #D5C5AD,
//        "tertiare": #B7ADA1,
//        "block": #E6DCD4
//);

$colors-background: (
        "default": #FEF9F6,
        "secundair": #D5C5AD,
        "tertiare": #B7ADA1,
        "block": #E6DCD4
);

$colors-font: (
        "default": #504538
);

$colors-font-hover: (
        "default": map-get($colors, orange-brown),
        "light":map-get($colors, "white"),
        "dark":map-get($colors, "brown")
);

$body-color: map-get($colors-font, "default");
$navbar-dark-color: map-get($colors-font, "default");
$navbar-dark-hover-color: map-get($colors-font, "default");
$navbar-dark-active-color: map-get($colors-font, "default");
$border-color: red;
//set--bs-secondary-rgb: 213, 197, 173;

.bg-primary {
        background-color: map-get($colors-background, "default");
}

.bg-secondary {
        background-color: map-get($colors-background, "secundair");
}

.bg-tertiary {
        opacity: 1;
        background-color: map-get($colors-background, "tertiare");
}




//$white:map-get($colors, "white");
//$black:map-get($colors, "black");
//$default-primary-base-color:map-get($colors-font, "default");
//$default-primary-dark-color:map-get($colors-font, "dark");
//$default-primary-light-color:map-get($colors-font, "light");
//$default-secondary-base-color: #eee;
//$default-secondary-dark-color: #745E431A;
//$default-secondary-light-color: #f8f8f8;
//
//$default-background-color:map-get($colors-background, "default");
//$default-background-color-light:map-get($colors-background, "light");
//$default-background-color-dark:map-get($colors-background, "dark");
//$default-background-color-darker:map-get($colors-background, "darker");
//$default-background-color-darkest:map-get($colors-background, "darkest");
//
//
//$default-tertiare-background-color:map-get($colors, "light-brown");
//$font-muli: 'Cabin', 'Arial';
//$font-standard-size: 62.5%;
//$footer-dark-background-color:map-get($colors-background, "dark");
//
//$button-color-light:map-get($colors-button, "light");
//$button-color-dark:map-get($colors-button, "dark");
//$colors-border-primary: map-get($colors-border, "primary");
//$colors-border-secondary: map-get($colors-border, "primary");
////$background-color: #F8F8F8;
//$background-color: #FFF;
//
//
//:root {
//    --secondary-base-color: transparant;
//    --link-color: #{$button-color-dark} default;
//    --overlay-background: transparant; //In category pagina?
//    --link-hover-color: red;
//    --secondary-dark-color: #{$default-primary-base-color};
//}